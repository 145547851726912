import {
  useShowInstructions,
  type LayoutModule,
} from '@backstage-components/base';
import {useSubscription} from 'observable-hooks';
import {PropsWithChildren, useEffect, useMemo, useRef, type FC} from 'react';
import {
  Routes,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigate as useRouterNavigate,
  type NavigateFunction,
} from 'react-router';
import {ComponentDefinition, reactName} from './RouterDefinition';

export type RouterComponentDefinition = LayoutModule<
  typeof reactName,
  RouterContainerProps
>;

/**
 * Creates a `react-router` `Routes` node which also subscribes to `Router`
 * instructions in order to manage page navigation with the site `Instruction`
 * flows.
 */
export const RouterContainer: FC<PropsWithChildren<RouterContainerProps>> = (
  props
) => {
  const location = useLocation();
  const navigate = useRouterNavigate();
  const {observable, broadcast} = useShowInstructions(
    ComponentDefinition.instructions
  );
  const prefix = props.prefix ?? '';
  // Use a ref rather than state so that the useSubscription function can only
  // be a closure over the correct value because the ref is mutated in place
  const navigateFn = useRef(props.navigate ?? navigate);
  // There are two places that broadcast `:on-navigate` messages but both should
  // not trigger off of `Router:goto`. Setting `shouldBroadcastOnLocationChange`
  // allows the next `:on-navigate` broadcast to be skipped when a location
  // change is detected
  const shouldBroadcastOnLocationChange = useRef(true);
  useEffect(() => {
    navigateFn.current = props.navigate ?? navigate;
  }, [navigate, props.navigate]);

  useSubscription(observable, {
    next: (instruction) => {
      if (instruction.type === 'Router:redirect') {
        // Navigate with no consideration of the router's state since `:redirect`
        // is intended for off-site navigation or for on-site navigation with an
        // intentional target page load.
        window.location.href = instruction.meta.url;
      } else if (instruction.type === 'Router:goto') {
        const nextPath = `${prefix}${instruction.meta.path}`.toLowerCase();
        // Only act if the next path is different from the current.
        if (location.pathname.toLowerCase() !== nextPath) {
          broadcast({
            type: 'Router:on-navigate',
            meta: {currentPath: instruction.meta.path.toLowerCase(), query: {}},
          });
          // Skip the next `Router:on-navigate` broadcast.
          shouldBroadcastOnLocationChange.current = false;
          // Perform the navigation.
          navigateFn.current(nextPath);
          // When navigation is explicitly performed scroll to the top.
          window.scrollTo({left: 0, top: 0});
        }
      }
    },
  });

  // `query` in instructions must but a comma-delimited string for `list:`
  // middleware to work
  const query = useMemo(() => {
    const params = new URLSearchParams(location.search);
    const result: Record<string, string> = {};
    for (const key of params.keys()) {
      const values = params.getAll(key);
      const head = values.at(0);
      if (values.length === 1 && typeof head === 'string') {
        result[key] = head;
      } else if (values.length > 1) {
        result[key] = values.join(',');
      }
    }
    return result;
  }, [location.search]);

  // The `UiRouter` in `@backstage/ui-render` always produces at least a
  // fallback route so the minimum number of routes (before data loads) is 1
  const routes = useMemo(
    () => createRoutesFromChildren(props.children),
    [props.children]
  );
  const hasRoutes = useMemo(() => routes.length > 1, [routes]);
  const matches = useMemo(
    () => matchRoutes(routes, location) ?? [],
    [routes, location]
  );
  const firstMatch = matches[0];
  const currentPath = useMemo(() => {
    const routePath =
      typeof prefix === 'string'
        ? firstMatch?.route?.path?.replace(prefix, '')
        : firstMatch?.route.path;
    const locationPathname = prefix
      ? location.pathname.replace(prefix, '')
      : location.pathname;
    const locationPath =
      locationPathname === '' ? '/' : locationPathname.toLowerCase();
    return routePath ?? locationPath;
  }, [firstMatch, location, prefix]);

  // broadcast a route change instruction when currentPath changes
  useEffect(() => {
    if (!hasRoutes) {
      return;
    }

    if (shouldBroadcastOnLocationChange.current) {
      broadcast({
        type: 'Router:on-navigate',
        meta: {currentPath, query},
      });
    }
    shouldBroadcastOnLocationChange.current = true;

    const timeout = setTimeout(() =>
      broadcast({
        type: 'Router:on-navigate-done',
        meta: {currentPath, query},
      })
    );
    return () => {
      clearTimeout(timeout);
    };
  }, [broadcast, currentPath, hasRoutes, query]);

  // broadcast on-404-no-match instruction if no routes were matched
  useEffect(() => {
    // Don't trigger 404 if route data is currently loading
    if (!hasRoutes || props.isLoading === true) {
      return;
    }
    if (firstMatch?.route.path === '*') {
      broadcast({
        type: 'Router:on-404-no-match',
        meta: {currentPath},
      });
    }
  }, [broadcast, currentPath, firstMatch, hasRoutes, props.isLoading]);

  return <Routes>{props.children}</Routes>;
};

export interface RouterContainerProps {
  /**
   * Flag to indicate if the route data is being reloaded.
   * @default false
   */
  isLoading?: boolean;
  /**
   * Function called in order to navigate between pages, this function is used
   * in processing `Router:goto` instructions.
   * @default `useNavigate` from `react-router` package
   */
  navigate?: NavigateFunction;
  /**
   * If provided, `prefix` is prepended to every path before navigation is
   * triggered.
   */
  prefix?: string;
}
