import {Module, NextModule} from '@backstage-components/base';
import React from 'react';
import {RouterContainer, RouterComponentDefinition} from './RouterContainer';
import {ComponentDefinition} from './RouterDefinition';

type Kind = RouterComponentDefinition['component'];

type Props = RouterComponentDefinition['props'];

export const RouterModule: Module<Kind, Props> = {
  isContent(value): value is RouterComponentDefinition {
    return false;
  },
  isLayout(value): value is RouterComponentDefinition {
    return (
      value.component === ComponentDefinition.reactName &&
      value.cid === ComponentDefinition.id
    );
  },
  tryRender(definition) {
    if (RouterModule.isLayout(definition)) {
      return React.createElement(RouterContainer, definition.props);
    } else {
      return NextModule;
    }
  },
};
